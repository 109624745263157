import React from 'react';
import _isEqual from 'lodash/isEqual';

import { StickyButtons, UiKit } from 'components';
import { useRecommendationConfig } from '../hooks';

export default function RecommendationActions({
  routeFragementToDiscardOnCancel = ''
}) {
  const {
    mode,
    data,
    initialData,
    isProcessing = false,
    onReset,
    onConfirm = () => {}
  } = useRecommendationConfig();

  const isCreating = mode === 'CREATE';
  const isDataSame = _isEqual(initialData, data);
  const isSaveDisabled = !isCreating && isDataSame;
  const primaryButtonText = isCreating ? 'Create' : 'Save Changes';
  const processingText = isCreating ? 'Creating...' : 'Saving...';

  return (
    <StickyButtons
      onConfirm={onConfirm}
      data={data}
      initialData={initialData}
      isEditMode={!isCreating}
      isProcessing={isProcessing}
      routeFragementToDiscardOnCancel={routeFragementToDiscardOnCancel}
    >
      <UiKit.Button
        customType="inline"
        onClick={onReset}
        disabled={isProcessing}
      >
        Reset
      </UiKit.Button>
      <UiKit.Button
        customType="success"
        onClick={onConfirm}
        processing={isProcessing}
        processingText={processingText}
        wrapperClassName="stretch"
        disabled={isSaveDisabled}
      >
        {primaryButtonText}
      </UiKit.Button>
    </StickyButtons>
  );
}
